import { useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { QUERIES } from '../helpers/consts'
import { getPosts } from '../core/request'
import { Link, useParams } from 'react-router-dom'

function List() {
	const { category_slug } = useParams()
	const { status: postStatus, data: posts } = useQuery({
		queryKey: [QUERIES.POSTS, category_slug],
		queryFn: () => {
			return getPosts(
				`&sort_field=id&sort_order=desc&filter={"status":true,"category_slug":"${category_slug}"}`
			)
		},
		enabled: !!category_slug,
	})
	useEffect(() => {
		if (postStatus) {
			var headID = document.getElementsByTagName('body')[0]
			var newScript2 = document.createElement('script')
			newScript2.type = 'text/javascript'
			newScript2.src = '/scripts/plugins.js'
			headID.appendChild(newScript2)
			var newScript3 = document.createElement('script')
			newScript3.type = 'text/javascript'
			newScript3.src = '/scripts/main.js'
			headID.appendChild(newScript3)
			return () => {
				headID.removeChild(newScript2)
				headID.removeChild(newScript3)
			}
		}
	}, [postStatus])

	return (
		<section className='gallery-page-wrapper'>
			<div className='container'>
				{/* <ul className='menu-wrapper' id='menu'>
					<li>
						<a href='#menu' className='headline-lato selected'>
							show all
						</a>
					</li>
					<li>
						<a href='#menu' data-filter='.image' className='headline-lato'>
							images
						</a>
					</li>
					<li>
						<a href='#menu' data-filter='.link' className='headline-lato'>
							links
						</a>
					</li>
					<li>
						<a href='#menu' data-filter='.slideshow' className='headline-lato'>
							slideshow
						</a>
					</li>
					<li>
						<a href='#menu' data-filter='.video' className='headline-lato'>
							video
						</a>
					</li>
				</ul> */}
				<div className='container row'>
					<div className='wrapper isp-wrap'>
						<div className='clearfix' style={{  marginTop: '300px' }} id='list-item'>
							{posts?.entities?.map((entity) =>
								entity.video_url ? (
									<div className='figure video'>
										<div className='item clearfix'>
											<div className='item-img'>
												<img
													src='/images/gallery/galleryP1.jpg'
													alt='image_text'
												/>
												<div className='figcaption'>
													<a
														href={entity.video_url}
														className='zoom'
														title='video'
													>
														<i className='fa fa-play'></i>
													</a>
												</div>
											</div>
											<div className='item-content'>
												<Link to={'/details/' + entity.id}>
													<h4 className='headline-lato'>{entity.title}</h4>
												</Link>
											</div>
										</div>
									</div>
								) : entity.media?.length ? (
									<div className='figure slideshow'>
										<div className='item clearfix'>
											<div className='item-img slide-show'>
												{entity.featured_image_url && (
													<img
														src={
															process.env.REACT_APP_BUCKET_URL! +
															entity?.featured_image_url
														}
														style={{ height: '274px' }}
														alt='image_text'
													/>
												)}
												{entity.media.map((image, i) => (
													<img
														key={i}
														src={process.env.REACT_APP_BUCKET_URL! + image.url}
														style={{ height: '274px' }}
														alt='image_text'
													/>
												))}
											</div>
											<span className='prev-slide'>
												<i className='fa fa-angle-left'></i>
											</span>
											<span className='next-slide'>
												<i className='fa fa-angle-right'></i>
											</span>
											<div className='item-content'>
												<Link to={'/details/' + entity.id}>
													<h4 className='headline-lato'>{entity.title}</h4>
												</Link>
											</div>
										</div>
									</div>
								) : entity.featured_image_url ? (
									<div className='figure image'>
										<div className='item clearfix'>
											<div className='item-img'>
												<img
													src={
														process.env.REACT_APP_BUCKET_URL! +
														entity?.featured_image_url
													}
													height={274}
													alt='image_text'
												/>
												<div className='figcaption'>
													<a
														href={
															process.env.REACT_APP_BUCKET_URL! +
															entity?.featured_image_url
														}
														className='zoom'
													>
														<i className='fa fa-file-image-o'></i>
													</a>
												</div>
											</div>
											<div className='item-content'>
												<Link to={'/details/' + entity.id}>
													<h4 className='headline-lato'>{entity.title}</h4>
												</Link>
											</div>
										</div>
									</div>
								) : (
									<div className='figure link'>
										<div className='item  clearfix'>
											<div className='item-img '>
												<img
													src='/images/gallery/galleryP1.jpg'
													alt='image_text'
													height={274}
												/>
												<div className='figcaption'>
													<Link to={'/details/' + entity.id}>
														<i className='fa fa-link'></i>
													</Link>
												</div>
											</div>
											<div className='item-content'>
												<Link to={'/details/' + entity.id}>
													<h4 className='headline-lato'>{entity.title}</h4>
												</Link>
											</div>
										</div>
									</div>
								)
							)}
						</div>
						{/* <div className='btn-load'>
							<a className='btn btn-grey '>load more</a>
						</div> */}
					</div>
				</div>
			</div>
		</section>
	)
}

export default List
