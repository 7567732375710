import React, { useEffect } from 'react'

function Contact() {
	useEffect(() => {
		var headID = document.getElementsByTagName('body')[0]
		var newScript2 = document.createElement('script')
		newScript2.type = 'text/javascript'
		newScript2.src = 'scripts/plugins.js'
		headID.appendChild(newScript2)
		var newScript3 = document.createElement('script')
		newScript3.type = 'text/javascript'
		newScript3.src = 'scripts/main.js'
		headID.appendChild(newScript3)
		return () => {
			headID.removeChild(newScript2)
			headID.removeChild(newScript3)
		}
	}, [])
	return (
		<section className='store'>
			<div className='container'>
				<div className='row'>
					<div className='wrapper clearfix'>
						<aside className='content contact-wrap'>
							<div id='map-wrapper'>
								<iframe
									src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d815.688325544921!2d4.5667074288414105!3d7.7568107784100295!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10382765824a1f21%3A0xc9912241f9020d5a!2sCatholic%20Diocese%20of%20Osogbo!5e1!3m2!1sen!2sng!4v1705874553968!5m2!1sen!2sng'
									width='100%'
									height='100%'
									style={{ border: 0 }}
									allowFullScreen={true}
									loading='lazy'
									referrerPolicy='no-referrer-when-downgrade'
									title='bishop house'
								></iframe>
							</div>
							<div className='contact-form clearfix'>
								<form method='post' name='contact' id='contact'>
									<div className='form-group'>
										<input
											type='text'
											className='form-control text'
											id='name'
											name='name'
											placeholder='Your name'
										/>
									</div>
									<div className='form-group'>
										<input
											type='email'
											className='form-control text'
											id='eml'
											name='eml'
											placeholder='Your E-mail '
										/>
									</div>
									<div className='form-group'>
										<input
											type='text'
											className='form-control text'
											id='phone'
											name='phone'
											placeholder='Phone'
										/>
									</div>
									<div className='form-group1'>
										<textarea
											className='form-control texta'
											id='comment'
											name='comment'
											placeholder='Message '
										></textarea>
									</div>
									<button className='submit'>send message</button>
									<div className='contact-page-form form-message contactpage'>
										<div>
											<div className='loader'>Loading...</div>
										</div>
									</div>
								</form>
							</div>
							<div className='contact-address '>
								{/* <p className='paragraph_opensans'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's.
                    <br />
                    standard dummy text ever since the 1500s, when an unknown printer took a galley
                    of type.
                  </p> */}
								<div className='fig-addrs clearfix'>
									<p>Phone:</p>
									<div className='address-cont'>
										<i className='fa fa-phone'></i>
										<a href='tel:08105584018'>
											0(810) 558 4018 <br />
										</a>
									</div>
								</div>{' '}
								{/* End of figure address */}
								<div className='fig-addrs clearfix'>
									<p>Email:</p>
									<div className='address-cont'>
										<i className='fa fa-envelope'></i>
										<a href='mailto:contact@catholicdioceseosogbo.org'>
											contact@catholicdioceseosogbo.org
										</a>
									</div>
								</div>{' '}
								{/* End of figure address */}
								<div className='fig-addrs clearfix'>
									<p>address:</p>
									<div className='address-cont'>
										<i className='fa fa-map-marker'></i>
										<span>
											Bishop's House, P.O. Box 78, Oke-Ayepe, Osogbo, Osun
											State, Nigeria
										</span>
									</div>
								</div>{' '}
								{/* End of figure address */}
							</div>
						</aside>
						<aside className='sidebar'>
							{/* <div className='item-menu'>
                  <div className='item-menu-wrap'>
                    <h5>Event catagories</h5>
                    <ul className='catagory'>
                      <li>
                        <a href=''>children</a>
                      </li>
                      <li>
                        <a href=''>classes</a>
                      </li>
                      <li>
                        <a href=''>community</a>
                      </li>
                      <li>
                        <a href=''>health</a>
                      </li>
                      <li>
                        <a href=''>more</a>
                      </li>
                    </ul>
                  </div>
                </div> */}
							{/*  End of item-menu */}
							{/* <div className='item-menu '>
                  <div className='item-menu-wrap'>
                    <h5>Past event</h5>
                    <div className='item-wrap clearfix'>
                      <div className='item-fig'>
                        <img src='/images/footer-img1.jpg')} alt='image' />
                      </div>
                      <div className='item-content'>
                        <h6>
                          <a href='#'>Pastor's Bible Study</a>
                        </h6>
                        <p>April 10, 2015</p>
                      </div>
                    </div>
                    <div className='item-wrap clearfix'>
                      <div className='item-fig'>
                        <img src='/images/footer-img2.jpg')} alt='image' />
                      </div>
                      <div className='item-content'>
                        <h6>
                          <a href='#'>Pastor's Bible Study</a>
                        </h6>
                        <p>April 10, 2015</p>
                      </div>
                    </div>
                    <div className='item-wrap clearfix'>
                      <div className='item-fig'>
                        <img src='/images/content3.jpg')} alt='image' />
                      </div>
                      <div className='item-content'>
                        <h6>
                          <a href='#'>Pastor's Bible Study</a>
                        </h6>
                        <p>April 10, 2015</p>
                      </div>
                    </div>
                  </div>
                </div>
                 End of item-menu */}
							{/* <div className='item-menu'>
                  <div className='item-menu-wrap'>
                    <h5>photo</h5>
                    <ul className='item-photo clearfix'>
                      <li>
                        <a href=''>
                          <img src='/images/footer-img2.jpg')} alt='image' />
                        </a>
                      </li>
                      <li>
                        <a href=''>
                          <img src='/images/photo/footer-img1.jpg')} alt='image' />
                        </a>
                      </li>
                      <li>
                        <a href=''>
                          <img src='/images/photo/jesus.jpg')} alt='image' />
                        </a>
                      </li>
                      <li>
                        <a href=''>
                          <img src='/images/photo/jesus1.jpg')} alt='image' />
                        </a>
                      </li>
                      <li>
                        <a href=''>
                          <img src='/images/photo/content3.jpg')} alt='image' />
                        </a>
                      </li>
                      <li>
                        <a href=''>
                          <img src='/images/photo/jesus2.jpg')} alt='image' />
                        </a>
                      </li>
                      <li>
                        <a href=''>
                          <img src='/images/photo/jesus3.jpg')} alt='image' />
                        </a>
                      </li>
                      <li>
                        <a href=''>
                          <img src='/images/photo/jesus.jpg')} alt='image' />
                        </a>
                      </li>
                      <li>
                        <a href=''>
                          <img src='/images/photo/footer-img2.jpg')} alt='image' />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
							{/*  End of item-menu */}
						</aside>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Contact
