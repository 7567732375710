/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormikValues} from 'formik'
import {useMemo, useState} from 'react'

export function Children({setState, formik}: {setState: any; formik: FormikValues}) {
  const len = useMemo(() => formik.values.children.length - 1, [formik.values.children])
  const [note, setNote] = useState<string | undefined>()
  return (
    <>
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h2 className='text-dark' style={{marginTop: '0px'}}>
          Child Details
        </h2>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {/* begin::Form group first_name */}
      <div className='col-lg-12'>
        <div className='col-lg-6'>
          <label className='class="form-label fw-bolder text-dark fs-6 required'>
            First name<span className='text-danger'>*</span>
          </label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps(`children[${len}][first_name]`)}
            className='form-control form-control-lg form-control-solid'
          />
        </div>
        <div className='col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6 required'>
            Last name/Surname<span className='text-danger'>*</span>
          </label>
          <input
            placeholder='Last name'
            type='text'
            {...formik.getFieldProps(`children[${len}][last_name]`)}
            className='form-control form-control-lg form-control-solid'
          />
        </div>
        <div className='col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Other Names/Middle Name</label>
          <input
            placeholder='Middle name'
            type='text'
            {...formik.getFieldProps(`children[${len}][other_name]`)}
            className='form-control form-control-lg form-control-solid'
          />
        </div>

        {/* begin::Form group Email */}
        <div className='col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Email</label>
          <input
            placeholder='Email'
            type='email'
            {...formik.getFieldProps(`children[${len}][email]`)}
            className='form-control form-control-lg form-control-solid'
          />
        </div>
        <div className='col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>
            Date of Birth<span className='text-danger'>*</span>
          </label>
          <input
            placeholder='Date of Birth'
            type='date'
            {...formik.getFieldProps(`children[${len}][birthday]`)}
            className='form-control form-control-lg form-control-solid'
          />
        </div>
        <div className='col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Phone Number</label>
          <input
            placeholder='Phone Number'
            type='phone'
            {...formik.getFieldProps(`children[${len}][phone]`)}
            className='form-control form-control-lg form-control-solid'
          />
        </div>
        <div className='col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>
            Gender<span className='text-danger'>*</span>
          </label>
          <select className='form-control' {...formik.getFieldProps(`children[${len}][gender]`)}>
            <option value=''> -- Select One -- </option>
            <option value='male'>Male</option>
            <option value='female'>Female</option>
            <option value='prefer_not_to_say'>Prefer not to say</option>
          </select>
        </div>
        <div className='col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>
            Highest Education Level<span className='text-danger'>*</span>
          </label>
          <select
            className='form-control'
            {...formik.getFieldProps(`children[${len}][highest_education]`)}
          >
            <option value=''> -- Select One -- </option>
            <option value='No formal education'>No formal education</option>
            <option value='Primary education'>Primary education</option>
            <option value='Secondary education'>Secondary education or high school</option>
            <option value='Vocational qualification'>Vocational qualification</option>
            <option value="Bachelor's degree">Bachelor's degree</option>
            <option value="Master's degree">Master's degree</option>
            <option value='Doctorate or higher'>Doctorate or higher</option>
          </select>
        </div>
        <div className='col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>
            Employment Status
            <span className='text-danger'>*</span>
          </label>
          <select
            className='form-control'
            {...formik.getFieldProps(`children[${len}][employment_status]`)}
          >
            <option value=''> -- Select One -- </option>
            <option value='Employed'>Employed</option>
            <option value='Unemployed'>Unemployed</option>
            <option value='Student'>Student</option>
          </select>
        </div>
        {formik.values.children[len].employment_status === 'Student' && (
          <>
            <div className='col-lg-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Course of Study</label>
              <input
                placeholder='Course of Study'
                type='text'
                {...formik.getFieldProps(`children[${len}][course]`)}
                className='form-control form-control-lg form-control-solid'
              />
            </div>
            <div className='col-lg-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Name of Institution</label>
              <input
                placeholder='Name of Institution'
                type='text'
                {...formik.getFieldProps(`children[${len}][school_name]`)}
                className='form-control form-control-lg form-control-solid'
              />
            </div>
          </>
        )}
      </div>
      <div className='text-danger'>{note}</div>

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='button'
          style={{width: 'auto', marginRight: '2px', paddingLeft: '40px', paddingRight: '40px'}}
          onClick={() => {
            formik.setFieldValue(`children[${len}]`, undefined)
            setState(false)
          }}
        >
          <span className='indicator-label'>Discard</span>
        </button>
        <button
          type='button'
          style={{width: 'auto', marginLeft: '2px', paddingLeft: '40px', paddingRight: '40px'}}
          onClick={() => {
            const val = formik.values.children[len]
            console.log(val)
            if (
              !val.first_name ||
              !val.last_name ||
              !val.gender ||
              !val.birthday ||
              !val.employment_status ||
              !val.highest_education
            )
              setNote('All fields mark * are required fields. kindly fill them before submitting')
            else {
              setNote(undefined)
              formik.setFieldValue(`children[${len}][status]`, true)
              setState(false)
            }
          }}
        >
          <span className='indicator-label'>Add Child</span>
        </button>
      </div>
      {/* end::Form group */}
    </>
  )
}
