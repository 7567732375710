import React, {useEffect, useState, useMemo} from "react";
import { FaInstagram, FaXTwitter, FaFacebook, FaYoutube, FaSpotify } from 'react-icons/fa6'
import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "../helpers/consts";
import { getPosts } from "../core/request";
import { Link } from "react-router-dom";

function Footer() {
	const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.classList.remove("modal-open");
  };
  useEffect(() => {
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);


	const { data: posts } = useQuery({
    queryKey: [QUERIES.POSTS, ["vocations", "external_links"]],
    queryFn: () => {
      return getPosts(
        `&sort_field=id&sort_order=desc&filter={"status":true,"category_slug":["vocations", "external_links"]}`
      );
    },
  });

  const { vocations, external_links } = useMemo(() => {
    const vocations = posts?.entities?.find((entity) =>
      entity.categories?.find((c) => c.slug === "vocations")
    );
    const external_links = posts?.entities?.find((entity) =>
      entity.categories?.find((c) => c.slug === "external_links")
    );
    return { vocations, external_links };
  }, [posts]);




	return (
<>
		<footer>
			<div>
        <div>
          {modalOpen && (
            <div className="modal" id="overlay-event">
              <div className="modal-overlay" onClick={closeModal}></div>
              <div className="modal-content">
                <div>
                  <span
                    className="close"
                
                    id="close_auth"
                    onClick={closeModal}
                    style={{ display: "" }}
                  >
                    &times;
                  </span>
                  <br />
                  <br />
                  

                  <div
                    className="modal-event clearfix"
                    style={{ overflowY: "auto", maxHeight: "30%" }}
                  >
                

<div className="vocation-info">
{vocations && (
       <>
               <div className="logo">
                  <img
                    src={
                      process.env.REACT_APP_BUCKET_URL! +
                      vocations.featured_image_url
                    }
                    alt="image_text"
                    style={{ height: "100px" }}
                  />
                </div>
                <br />
                <br />
              <div className="figcaption-content">
            
                <p
                  dangerouslySetInnerHTML={{
                    __html: vocations.description!,
                  }}
                ></p>
              </div>
              </>
     
      )}
</div>
<br />
	<br/>
	<br />
	<br/>

                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
			<div className='container'>
				<div className='row'>
					<div className='row left'>
						<div className='why'>
							<center>
								<h4>Catholic Diocese of Osogbo</h4>
								<img
									src='/images/coat_of_arm.png'
									alt='image_text'
									height='200px'
								/>
							</center>
						</div>
						<div className='pages'>
						<h4>Pages</h4>
						<ul className="custom-bullets">
							<li><a
                  href={process.env.REACT_APP_BUCKET_URL + 'post/202403150817_STRATEGIC_PASTORAL.pdf'}
                  target='_blank'
                  rel='noreferrer'
                >
                  Pastoral Mission Strategic Plan
                </a></li>
							<li><a
                  href={process.env.REACT_APP_BUCKET_URL + 'media/202407312031_CATHOLIC_POLICY.pdf'}
                  target='_blank'
                  rel='noreferrer'
                >
                  Protection of Minors
                </a></li>
                {vocations && (
                  <li><Link to='#!' onClick={openModal}>{vocations.title}</Link></li>
                )}
						<li><Link to='/details/104'> Diocesan Administration </Link> </li>
						<li><Link to='/list/projects'>Support Our Ongoing Diocesan Projects</Link></li>
						</ul>
						{/*	<h4>Pages</h4>
							<ul>
								{footer_categories?.entities?.map((entity) => (
									<li key={entity.id}>
										<a href='#!'>{entity.title}</a>
									</li>
								))}
							</ul>*/}
						</div>
					</div>

					<div className='row right'>
						<div className='contact'>
							<h4>Contact us</h4>
							<p>
								Bishop's House, P.O. Box 78,
								<br />
								Oke-Ayepe, Osogbo,
								<br />
								Osun State, Nigeria
							</p>
							<p>
								Phone: <a href='tel:08105584018'>0(810) 558 4018</a>
								<br />
								Mail:{' '}
								<a href='mailto:contact@catholicdioceseosogbo.org'>
									contact@catholicdioceseosogbo.org
								</a>
							</p>
						</div>
						<div className='recent'>

							{/* <h4>Recent Sermons</h4>
							{homilies?.entities?.map((entity) => (
								<div key={entity.id} className='figure row'>
									<div>
										<img src='/images/footer-img1.jpg' alt='image_text' />
									</div>
									<div className='figcaption'>
										<h5 className='heading'>
										<a href="#!" onClick={() => openSermon(entity)}>
                        {entity.title}
                      </a>
										</h5>
										<h5 className='date'>April 10, 2015</h5>
									</div>
								</div>
							))}*/}

              {external_links && (
                <>
                <h4>{external_links.title}</h4>
                <div className="figcaption-content">
              
                <p
                  dangerouslySetInnerHTML={{
                    __html: external_links.description!,
                  }}
                ></p>
                
              </div>
                </>
              )}
            </div>




					</div>
					</div>
				</div>


				<div  style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
	  <a className=" pad2y" href="https://youtube.com/@catholicdioceseofosogbo7266?si=YcxSuOV37sOCAmop" target="_blank" rel="noopener noreferrer">
        <FaYoutube size={30} color="red" />
      </a>
	  <a href="https://www.facebook.com/communicationcatholicdioceseosogbo" target="_blank" rel="noopener noreferrer">
        <FaFacebook size={30} color="#1877F2"/>
      </a>
	  <a href="https://www.instagram.com/osogbodiocese?igsh=YzljYTk1ODg3Zg==" target="_blank" rel="noopener noreferrer">
        <FaInstagram size={30} color= '#cc2366'/> 
      </a>
      <a href="https://x.com/OsogboDiocese?t=INX2y0MNFgur-AI1LgICgg&s=09" target="_blank" rel="noopener noreferrer">
        <FaXTwitter size={30} color='#14171A'/>
      </a> 
	  <a href="https://open.spotify.com/show/3m1X11AZShRnXd48nKyYSL" target="_blank" rel="noopener noreferrer">
        <FaSpotify size={30} color='#1DB954'/>
      </a> 
    </div>
				<h6>
					&copy; {new Date().getFullYear()} Catholic Diocese of Osogbo. Powered
					by{' '}
					<a
						href='https://starlightesolutions.com/'
						target='_blank'
						rel='noreferrer'
					>
						<span>Starlight Inc.</span>
					</a>
				</h6>
			
		</footer>
    </>
	)
}

export default Footer
