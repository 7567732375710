import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { QUERIES } from '../helpers/consts';
import { getPost, getPosts } from '../core/request';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

function Details() {
  const { id } = useParams();
  const [updatedDescription, setUpdatedDescription] = useState('');

  const { status: postStatus, data: post } = useQuery({
    queryKey: [QUERIES.POSTS, id],
    queryFn: () => getPost(id),
    enabled: !!id,
  });

  const { data: posts } = useQuery({
    queryKey: [QUERIES.POSTS, 'event'],
    queryFn: () =>
      getPosts(
        `&sort_field=id&sort_order=desc&filter={"status":true,"category_slug":["event"]}`
      ),
  });

  const { next_events } = useMemo(() => {
    const events = posts?.entities?.filter((entity) =>
      entity.categories?.find((c) => c.slug === 'event')
    );
    events?.sort(
      (a, b) => new Date(a.date!).getTime() - new Date(b.date!).getTime()
    );
    let next_events = events?.filter(
      (entity) =>
        entity.categories?.find((c) => c.slug === 'event') &&
        new Date(entity.date!) > new Date()
    );
    if (next_events?.length && next_events.length > 4)
      next_events = next_events.slice(0, 4);
    return {
      next_events,
    };
  }, [posts]);

  useEffect(() => {
    if (postStatus) {
      const headID = document.getElementsByTagName('body')[0];
      const newScript2 = document.createElement('script');
      newScript2.type = 'text/javascript';
      newScript2.src = '/scripts/plugins.js';
      headID.appendChild(newScript2);
      const newScript3 = document.createElement('script');
      newScript3.type = 'text/javascript';
      newScript3.src = '/scripts/main.js';
      headID.appendChild(newScript3);
      return () => {
        headID.removeChild(newScript2);
        headID.removeChild(newScript3);
      };
    }
  }, [postStatus, id]);

  useEffect(() => {
    if (typeof post?.description === 'string') {
      const bucketUrl = process.env.REACT_APP_BUCKET_URL || '';
      const updated = post.description.replace(/{{BUCKET_URL}}/g, bucketUrl);
      setUpdatedDescription(updated);
    } else {
      setUpdatedDescription('');
    }
  }, [post?.description, id]);

  return (
    <section className='store'>
      <div className='container'>
        <div className='row'>
          <div className='wrapper clearfix' style={{ marginTop: '100px' }}>
            <aside className='content blog-details'>
              {post?.featured_image_url && (
                <div className='blog-image'>
                  <img
                    src={process.env.REACT_APP_BUCKET_URL! + post?.featured_image_url}
                    alt='image_text'
                  />
                </div>
              )}
              <div className='about-god'>
                <div className='clearfix'>
                  <i className='fa fa-quote-left'></i>
                  <h5>{post?.title}</h5>
                </div>
                <p
                  className='paragraph_opensans'
                  dangerouslySetInnerHTML={{ __html: updatedDescription }}
                ></p>
              </div>
              {post?.media?.length ? (
                <figure className='gallery'>
                  <ul className='gallery-wrapper clearfix'>
                    {post?.media.map((entity) => (
                      <li key={entity.id} className='items'>
                        <a
                          href={process.env.REACT_APP_BUCKET_URL + entity.url}
                          className='zoom '
                        >
                          <img
                            className='gallery_img'
                            src={process.env.REACT_APP_BUCKET_URL + entity.url}
                            alt='image_text'
                            height={250}
                          />
                          <span className='h-effect'>
                            <img
                              src='/images/gallery_hover_icon.png'
                              className='item-container'
                              alt='image_text'
                            />
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </figure>
              ) : (
                ''
              )}
            </aside>
            <aside className='sidebar'>
              <div className='item-menu '>
                <div className='item-menu-wrap'>
                  <h5>Upcoming event</h5>
                  {next_events?.map((entity) => (
                    <div key={entity.id} className='item-wrap clearfix'>
                      <div className='item-fig'>
                        <img
                          src={
                            entity.featured_image_url
                              ? process.env.REACT_APP_BUCKET_URL! +
                                entity?.featured_image_url
                              : '/images/footer-img1.jpg'
                          }
                          alt='image_text'
                        />
                      </div>
                      <div className='item-content'>
                        <h6>
                          <Link to={'/details/' + entity.id}>{entity.title}</Link>
                        </h6>
                        <p>{dayjs(entity.date).format('MMM DD, YYYY')}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Details;
