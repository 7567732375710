import axios from 'axios'
import {
	CategorysQueryResponse,
	GalleriesQueryResponse,
	ID,
	Post,
	PostsQueryResponse,
	UserModel,
} from './model'

const API_URL = process.env.REACT_APP_API_URL
const POST_URL = `${API_URL}/post`
const GALLERY_URL = `${API_URL}/gallery`
const CATEGORY_URL = `${API_URL}/category`
// const USER_URL = `${API_URL}/user`

const getPosts = (query: string): Promise<PostsQueryResponse> => {
	return axios.get(`${POST_URL}?${query}`)
}
const getPost = (id: ID): Promise<Post | undefined> => {
	return axios.get(`${POST_URL}/${id}`)
}
const getGalleries = (query: string): Promise<GalleriesQueryResponse> => {
	return axios.get(`${GALLERY_URL}?${query}`)
}
const getCategorys = (query: string): Promise<CategorysQueryResponse> => {
	return axios.get(`${CATEGORY_URL}?${query}`)
}
const register = (values: any): Promise<UserModel> => {
	return axios.post(process.env.REACT_APP_API_URL + '/auth/register', values)
}

// const getUser = (id: ID): Promise<UserModel | undefined> => {
// 	return axios.get(`${USER_URL}/${id}`)
// }
export { getPosts, getPost, getGalleries, getCategorys, register }
