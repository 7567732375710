import "./navbar.css";
import {
  AiOutlineClose,
  AiOutlineMenu,
  AiOutlineArrowLeft,
} from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import React, { useMemo, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "../../helpers/consts";
import { getCategorys, getPost } from "../../core/request";
import { Introduction } from "../registration/Introduction";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  // const menuToggle = () => setToggleMenu(!toggleMenu);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true)
    setToggleMenu(false)
    document.body.classList.add("modal-open")
  }

  const closeModal = () => {
    setModalOpen(false);
    document.body.classList.remove("modal-open")
  }
  useEffect(() => {
    return () => {
      document.body.classList.remove("modal-open")
    }
  }, [])

  const { pathname } = useLocation();
  const { data: header_categories } = useQuery({
    queryKey: [QUERIES.CATEGORIES, "header"],
    queryFn: () => {
      return getCategorys(
        `sort_field=order&sort_order=asc&filter={"status":true,"show_on_header":true}`
      )
    },
  })
  const id = useMemo(() => {
    let i = "";
    if (pathname.includes("/details/"))
      i = pathname.split("/")[2].replaceAll("_", " ");
    return i;
  }, [pathname]);
  const { data: post } = useQuery({
    queryKey: [QUERIES.POSTS, id],
    queryFn: () => {
      return getPost(id);
    },
    enabled: !!id,
  });
  const title = useMemo(() => {
    let t = "";
    if (pathname === "contact-us") t = "contact us";
    else if (pathname.includes("/list"))
      t = pathname.split("/")[2].replaceAll("_", " ");
    else if (id && post?.title) t = post.title;
    return t;
  }, [pathname, id, post]);

  const Menu = () => (
    <>
      <p>
        {header_categories?.entities?.map((entity) => (
          <li
            key={entity.id}
            className={entity.child_categories ? "dropdown" : ""}
          >
            <Link className="tab-text" to={"/list/" + entity.slug}>
              {entity.title}
            </Link>
            {entity.child_categories && (
              <ul className="dropdown-content">
                <Link className="" onClick={() => setToggleMenu(true)}>
                  {" "}
                  <div className="backtomenu">
                    <AiOutlineArrowLeft
                      onClick={() => setToggleMenu(true)}
                    ></AiOutlineArrowLeft>{" "}
                    Back to Menu
                  </div>{" "}
                </Link>
                {entity.child_categories.map((child) => (
                  <a key={child.id} href="#!">
                    <Link
                      to={"/list/" + child.slug}
                      onClick={() => setToggleMenu(false)}
                    >
                      {child.title}
                    </Link>
                  </a>
                ))}
              </ul>
            )}
          </li>
        ))}
        <li className="dropdown">
          {" "}
          <Link
            className="tab-text"
            to="/contact-us"
            onClick={() => setToggleMenu(false)}
          >
            GET IN TOUCH
          </Link>
        </li>

        <li id="registeration_button" className="dropdown">
          {" "}
          <Link className="regbtn" onClick={openModal}>
            REGISTER
          </Link>
        </li>
      </p>
    </>
  )

  return (
    <>
      <div>
        {modalOpen && (
          <div className="modal" id="overlay-event">
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modal-content" >
              <div>
                <span
                  className="close"
                  href='#!'
                  id="close_auth" 
                  onClick={closeModal}
                  style={{ display: '' }}
                >
                  &times;
                </span>
                <br />
                <br />
                <br />
                <br />

                <div
                  className="modal-event clearfix"
                  style={{ overflowY: "auto", maxHeight: "30%" }}
                >
                  <h1 className="logo" style={{ margin: 0 }}>
                    <Link to="/">
                      <img
                        src="/images/coat_of_arm.png"
                        alt="imag-logo"
                        style={{ height: "100px" }}
                      />
                    </Link>
                  </h1>
                  <Introduction />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>


  

      <header className={pathname !== "/" ? "s-header" : ""}>
        <div className="  top-banner">
          <div className="overlay"></div>
          <div className="ch__navbar-logo">
            <Link to="/" className="">
              <img src="/images/coat_of_arm.png" alt="logo" height="100px" />
            </Link>
          </div>

          <div className="ch__container">
            <div className="ch__navbar">
              <div className="ch__navbar-links">
                <div className="ch__navbar-links_container">
                  <div className="ch__logo">
                    <Link to="/" className="logo">
                      <img
                        src="/images/coat_of_arm.png"
                        alt="logo"
                        height="60px"
                      />
                    </Link>
                  </div>

                  <Menu />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ backgroundColor: "#108146", color: "white" }}
            className="ch__navbar-menu"
          >
            {toggleMenu ? (
              <AiOutlineClose
                style={{
                  position: "absolute",
                  top: 1,
                  left: 1,
                  fontSize: "3rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "#fff",
                }}
                onClick={() => setToggleMenu(false)}
              />
            ) : (
              <AiOutlineMenu
                color="#fff"
                size={50}
                style={{ cursor: "pointer", left: 1 }}
                onClick={() => setToggleMenu(true)}
              />
            )}
            {toggleMenu && (
              <div
                style={{
                  backgroundColor: "#dfe9e3",
                }}
                className="ch__navbar-menu_container scale-up-center"
              >
                <div className="ch__navbar-menu_container-links">
                  <Menu />
                </div>
              </div>
            )}
          </div>
        </div>
        {pathname !== "/" && (
          <div className="button-wrapper">
            <div className="container">
              <div className="row">
                <a href="#!" className="btn-content animated fadeInUp">
                  {title}
                </a>
              </div>
            </div>
          </div>
        )}
      </header>
    </>
  );
};
export default Navbar;
