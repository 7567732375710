/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react"
import { FormikValues, useFormik } from "formik"
import * as Yup from "yup"
import clsx from "clsx"
import countries from "../../helpers/countries.json"
import deaneriesjson from "../../helpers/parishes.json"
import professionjson from "../../helpers/profession.json"
import { Children } from "./Children"
import Select from "react-select"
import { UserModel } from "../../core/model"
import { register } from "../../core/request"

const initialValues: UserModel = {
  title: "",
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  gender: "",
  marital_status: "",
  highest_education: "",
  profession: "",
  current_address: "",
  birthday: "",
  country: "",
  other_name: "",
  home_parish: "",
  home_deanery: "",
  residential_parish: "",
  residential_deanery: "",
  sacraments: [],
  children: [],
  support: "",
  spouse_name: "",
  employment_status: "",
  course: "",
  school_name: "",
};
const deaneries: any = deaneriesjson;
const profession: any = professionjson;
export function Registration({ setState }: { setState: any }) {
  const registrationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    email: Yup.string()
      .email("Wrong email format")
      .required("Email is required"),
    phone: Yup.string().required("Phone Number is required"),
    last_name: Yup.string().required("Last name is required"),
    gender: Yup.string().required().label("Gender"),
    marital_status: Yup.string().required().label("Marital Status"),
    highest_education: Yup.string().required().label("Highest Education Level"),
    profession: Yup.string().required().label("Profession"),
    current_address: Yup.string().required().label("Current Address"),
    birthday: Yup.string().required().label("Date of birth"),
    country: Yup.string().required().label("Country"),
    other_name: Yup.string().label("Other Names"),
    title: Yup.string().required().label("Title"),
    sacraments: Yup.array().required().label("Sacraments"),
    home_parish: Yup.string().required().label("Home Parish"),
    home_deanery: Yup.string().required().label("Home Deanery"),
    employment_status: Yup.string().required().label("Employment Status"),
    course: Yup.string().label("Course"),
    school_name: Yup.string().label("School"),
  });
  const [openChild, setOpenChild] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values: FormikValues, { setStatus }: any) => {
      try {
        setStatus();
        await register(values);

        document.getElementById("close_auth")?.click();
        setStatus(values.message);
      } catch (error) {
        setStatus("The registration details is incorrect");
      }
    },
  });
  useEffect(() => {
    if (formik.values.country) {
      const country = countries.find((c) => c.name === formik.values.country);
      if (country) formik.setFieldValue("phone", "+" + country.phone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country]);
  useEffect(() => {
    const res = Object.keys(deaneries).find((d) =>
      deaneries[d].includes(formik.values.home_parish)
    );
    formik.setFieldValue("home_deanery", res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.home_parish]);

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const groupBadgeStyles: any = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
      style={{ marginLeft: "15px", marginRight: "15px" }}
    >
      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}
      <div id="close_auth"></div>
      {/* begin::Form group first_name */}
      <div className="row fv-row mb-7">
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Title<span className="text-danger">*</span>
          </label>
          <select className="form-control" {...formik.getFieldProps("title")}>
            <option value=""> -- Select One -- </option>
            <option value="Fr.">Fr.</option>
            <option value="Sr.">Sr.</option>
            <option value="Mr.">Mr.</option>
            <option value="Ms.">Ms.</option>
            <option value="Mrs.">Mrs.</option>
            <option value="Miss">Miss</option>
            <option value="Dr.">Dr.</option>
            <option value="Prof.">Prof.</option>
            <option value="Engr.">Engr.</option>
            <option value="Sir">Sir</option>
            <option value="Lady">Lady</option>
            <option value="Honorable">Honorable</option>
            <option value="Chief">Chief</option>
          </select>
        </div>
        <div className="col-lg-6">
          <label className='class="form-label fw-bolder text-dark fs-6'>
            First name<span className="text-danger">*</span>
          </label>
          <input
            placeholder="First name"
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("first_name")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Last name/Surname<span className="text-danger">*</span>
          </label>
          <input
            placeholder="Last name"
            type="text"
            {...formik.getFieldProps("last_name")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Other Names/Middle Name
          </label>
          <input
            placeholder="Middle name"
            type="text"
            {...formik.getFieldProps("other_name")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>

        {/* begin::Form group Email */}
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Email<span className="text-danger">*</span>
          </label>
          <input
            placeholder="Email"
            type="email"
            {...formik.getFieldProps("email")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Date of Birth<span className="text-danger">*</span>
          </label>
          <input
            placeholder="Date of Birth"
            type="date"
            {...formik.getFieldProps("birthday")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Country Of Residence <span className="text-danger">*</span>
          </label>
          <select className="form-control" {...formik.getFieldProps("country")}>
            <option value=""> -- Select One -- </option>
            {countries.map((country, i) => (
              <option key={i} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Phone Number<span className="text-danger">*</span>
          </label>
          <input
            placeholder="Phone Number"
            type="phone"
            {...formik.getFieldProps("phone")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6 required ">
            Gender<span className="text-danger">*</span>
          </label>
          <select className="form-control" {...formik.getFieldProps("gender")}>
            <option value=""> -- Select One -- </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Marital Status<span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            {...formik.getFieldProps("marital_status")}
          >
            <option value=""> -- Select One -- </option>
            <option value="single">Single</option>
            <option value="married">Married</option>
            <option value="widowed">Widowed</option>
            <option value="prefer_not_to_say">Prefer not to say</option>
          </select>
        </div>
        {formik.values.marital_status === "married" && (
          <div className="col-lg-6">
            <label className="form-label fw-bolder text-dark fs-6">
              Spouse Name
            </label>
            <input
              placeholder="Spouse Name"
              type="text"
              {...formik.getFieldProps("spouse_name")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
            />
          </div>
        )}
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Sacraments Recieved
            <span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            {...formik.getFieldProps("sacraments")}
            multiple
          >
            <option value="Baptism">Baptism</option>
            <option value="Eucharist">Eucharist</option>
            <option value="Confirmation">Confirmation</option>
            {!formik.values.sacraments?.includes("Holy Order") && (
              <option value="Matrimony">Matrimony</option>
            )}
            {!formik.values.sacraments?.includes("Matrimony") && (
              <option value="Holy Order">Holy Order</option>
            )}
          </select>
        </div>
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Home Parish<span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            {...formik.getFieldProps("home_parish")}
          >
            <option value=""> -- Select One -- </option>
            {Object.keys(deaneries).map((d) => (
              <optgroup key={d} label={d + " DEANERY"}>
                {deaneries[d].map((home_parish: string, i: number) => (
                  <option key={i} value={home_parish}>
                    {home_parish}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </div>
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Residential Deanery
          </label>
          <input
            placeholder="Residential Deanery"
            type="text"
            {...formik.getFieldProps("residential_deanery")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Residential Parish
          </label>
          <input
            placeholder="Residential Parish"
            type="text"
            {...formik.getFieldProps("residential_parish")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Highest Education Level
            <span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            {...formik.getFieldProps("highest_education")}
          >
            <option value=""> -- Select One -- </option>
            <option value="No formal education">No formal education</option>
            <option value="Primary education">Primary education</option>
            <option value="Secondary education">
              Secondary education or high school
            </option>
            <option value="Post secondary education">
              Post Secondary Education
            </option>
            <option value="Vocational qualification">
              Vocational qualification
            </option>
            <option value="Bachelor's degree">Bachelor's degree</option>
            <option value="Master's degree">Master's degree</option>
            <option value="Doctorate or higher">Doctorate or higher</option>
          </select>
        </div>
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Employment Status
            <span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            {...formik.getFieldProps("employment_status")}
          >
            <option value=""> -- Select One -- </option>
            <option value="Employed">Employed</option>
            <option value="Unemployed">Unemployed</option>
            <option value="Student">Student</option>
          </select>
        </div>
        {formik.values.employment_status === "Student" && (
          <>
            <div className="col-lg-6">
              <label className="form-label fw-bolder text-dark fs-6">
                Course of Study
              </label>
              <input
                placeholder="Course of Study"
                type="text"
                {...formik.getFieldProps("course")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
              />
            </div>
            <div className="col-lg-6">
              <label className="form-label fw-bolder text-dark fs-6">
                Name of Institution
              </label>
              <input
                placeholder="Name of Institution"
                type="text"
                {...formik.getFieldProps("school_name")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
              />
            </div>
          </>
        )}
        <div className="col-lg-6">
          <label className="form-label fw-bolder text-dark fs-6">
            Occupation/Profession<span className="text-danger">*</span>
          </label>

          <Select
            defaultValue={
              formik.values.profession
                ? {
                    label: formik.values.profession,
                    value: formik.values.profession,
                  }
                : undefined
            }
            options={profession}
            formatGroupLabel={formatGroupLabel}
            className="text-left"
            onChange={(e: any) => formik.setFieldValue("profession", e.value)}
          />
        </div>
        <div className="col-lg-12">
          <label className="form-label fw-bolder text-dark fs-6">
            Current Address<span className="text-danger">*</span>
          </label>
          <textarea
            placeholder="Current Address"
            {...formik.getFieldProps("current_address")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        <div className="col-lg-12">
          <label className="form-label fw-bolder text-dark fs-6">
            Please support the Catholic Diocese of Osogbo
          </label>
          <br />
          <span>
            Your support helps us to continue the important work and mission of
            the Church, spreading the Good News of the Gospel to people across
            our Diocese and beyond. You can tick any area you will like to
            assist the diocese in the future
          </span>
          <select className="form-control" {...formik.getFieldProps("support")}>
            <option value=""> -- Select One -- </option>
            <option value="Evangelization">Evangelization</option>
            <option value="Vocations">Vocations</option>
            <option value="Youth Ministry and Empowerment">
              Youth Ministry and Empowerment
            </option>
            <option value="Physical Projects and developments">
              Physical Projects and developments
            </option>
            <option value="Liturgy- Choir, Altar Servers,">
              Liturgy- Choir, Altar Servers,
            </option>
            <option value="Communications">Communications</option>
            <option value="Marriage and family life">
              Marriage and family life
            </option>
            <option value="Oke-Maria Pilgrimage Centre">
              Oke-Maria Pilgrimage Centre
            </option>
            <option value="Diocesan Harvest">Diocesan Harvest</option>
            <option value="Catechist training">Catechist training</option>
            <option value="Priests ongoing formation and Priests welfare">
              Priests ongoing formation and Priests welfare
            </option>
            <option value="Pastoral Centres">Pastoral Centres</option>
            <option value="others">others</option>
          </select>
          {formik.errors.support && (
            <span role="alert" className="text-danger">
              {formik.errors.support}
            </span>
          )}
        </div>
        {formik.values.children.length ? (
          <div
            className="col-lg-12"
            style={{ textAlign: "left", marginTop: "10px" }}
          >
            <h2>Children</h2>
            <ol>
              {formik.values.children?.map((child: UserModel, i: number) =>
                child?.status ? (
                  <li key={i}>
                    {child.first_name} {child.last_name}
                  </li>
                ) : (
                  ""
                )
              )}
            </ol>
          </div>
        ) : (
          <></>
        )}
        {/* end::Form group */}
        <a
          onClick={() => {
            setOpenChild(true);
            formik.setFieldValue(
              `children[${formik.values.children.length}]`,
              initialValues
            );
          }}
          className="btn red-btn"
          style={{
            marginTop: "10px",
          }}
        >
          Add Children <b style={{ fontSize: "20px" }}>+</b>
        </a>
        {openChild ? <Children formik={formik} setState={setOpenChild} /> : ""}
      </div>
      {/* begin::Form group */}
      <div className="text-danger">
        All fields mark * are required fields. kindly fill them before
        submitting
      </div>
      <div className="text-center">
        <button
          type="button"
          disabled={formik.isSubmitting || !formik.isValid}
          style={{
            width: "auto",
            marginRight: "2px",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
          onClick={() => setState()}
        >
          <span className="indicator-label">Back</span>
        </button>
        <button
          type="submit"
          id="kt_sign_in_submit"
          //disabled={formik.isSubmitting || !formik.isValid}
          style={{
            width: "auto",
            marginLeft: "2px",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          {!formik.isSubmitting ? (
            <span className="indicator-label">Submit</span>
          ) : (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  );
}
