import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Landing from './pages/Landing'
//import Header from './pages/Header'
import Footer from './pages/Footer'
import Contact from './pages/Contact'
import List from './pages/List'
import Details from './pages/Details'
import Nav from './pages/navbar/Navbar'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const { PUBLIC_URL } = process.env

const App = () => {
	return (
		<>
		<BrowserRouter basename={PUBLIC_URL}>
		
			<Nav />
			<Routes>
				<Route path='details/:id' element={<Details />} />
				<Route path='list/:category_slug' element={<List />} />
				<Route path='contact-us' element={<Contact />} />
				<Route path='/' element={<Landing />} />
			</Routes>
			<Footer />
		</BrowserRouter>
		<ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
		</>
	)
}

export default App
