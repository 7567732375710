import { useState } from "react";
import { Registration } from "./Registration";

const Introduction = () => {
  const [state, setState] = useState<string>();

  return (
    <>
      {state === "register" ? (
        <Registration setState={setState} />
      ) : (
        <div>
          <div
            style={{
              padding: "10px",
              textAlign: "justify",
              width: "90%",
              margin: "auto",
            }}
          >
            <div>
              In the name of our Lord Jesus Christ, you are welcome to this
              Diocesan Data Portal. In Becoming one with Christ and His Church,
              we all have a place in the Divine Kingdom that has begun here on
              earth. This Bio-Data Form helps the Diocese to gather all her
              Children spread all over the Globe, just as Christ gathers His
              Flock to himself (cf. John 10:16). This project will help the
              Diocese to ascertain the identity and peculiarities of her Sons
              and Daughters, recognizing their special circumstances and be
              informed on the best way to serve their needs and interests.
            </div>
            <br />
            <div>Need Help?</div>
            Please call Fr Tony +2348109938829 or Fr Vincent +234803 898 9620
          </div>
          
          <div style={{ paddingBottom: "20px" }}>
            <div
              onClick={() => setState("register")}
              className="btn red-btn"
              style={{ marginRight: "3px" }}
            >
              Register
            </div>
          </div>

          <img
            src="/images/banner.jpeg"
            alt="imag-logo"
            style={{ width: "70%" }}
          />
          <h2>Privacy Policy</h2>
          <div
            style={{
              padding: "10px",
              textAlign: "justify",
              width: "90%",
              margin: "auto",
            }}
          >
            Registered parishioners are entered into a secure password protected
            database that is accessible only to the parish clergy. At no time is
            your data passed on to any third party for any purposes without your
            expressed consent.
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <div
              onClick={() => setState("register")}
              className="btn red-btn"
              style={{ marginRight: "3px" }}
            >
              Register
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export { Introduction };
