import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "../helpers/consts";
import { getGalleries, getPosts } from "../core/request";
import dayjs from "dayjs";
import { truncateString } from "../helpers/helpers";
import { useNavigate, Link } from "react-router-dom";
import { Post } from "../core/model";
import { Introduction } from "../pages/registration/Introduction";
import "../pages/navbar/Navbar";

function Landing() {
  const navigate = useNavigate();
  const { status: postStatus, data: posts } = useQuery({
    queryKey: [
      QUERIES.POSTS,
      ["slideshow", "welcome_message", "event", "about_us", "news_and_events"],
    ],
    queryFn: () => {
      return getPosts(
        `&sort_field=id&sort_order=desc&filter={"status":true,"category_slug":["slideshow","welcome_message","event","about_us", "news_and_events"]}`
      );
    },
  });
  const { status: galleryStatus, data: gallery } = useQuery({
    queryKey: [QUERIES.IMAGES],
    queryFn: () => {
      return getGalleries(
        `page_number=1&page_size=25&sort_field=id&sort_order=desc&filter={"status":true}`
      );
    },
  });

  const handleReadMore = (entity: Post) => {
    window.scrollTo({ top: 2, behavior: 'smooth' });
    return navigate("/details/" + entity.id);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.classList.remove("modal-open");
  };
  useEffect(() => {
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []); 
  useEffect(() => {
    if (postStatus === "success" && galleryStatus === "success") {
      var headID = document.getElementsByTagName("body")[0];
      var newScript2 = document.createElement("script");
      newScript2.type = "text/javascript";
      newScript2.src = "scripts/plugins.js";
      headID.appendChild(newScript2);
      var newScript3 = document.createElement("script");
      newScript3.type = "text/javascript";
      newScript3.src = "scripts/main.js";
      headID.appendChild(newScript3);
      return () => {
        headID.removeChild(newScript2);
        headID.removeChild(newScript3);
      };
    }
  }, [postStatus, galleryStatus]);
  const {
    slides,
    welcome_message,
    next_event,
    about_us,
    next_events,
    
    news_and_events,
  } = useMemo(() => {
    const slides = posts?.entities?.filter(
      (entity) =>
        entity.categories?.find((c) => c.slug === "slideshow") &&
        entity.featured_image_url
    );
    const welcome_message = posts?.entities?.find((entity) =>
      entity.categories?.find((c) => c.slug === "welcome_message")
    );
    let about_us = posts?.entities?.filter((entity) =>
      entity.categories?.find((c) => c.slug === "about_us")
    );
    if (about_us?.length && about_us.length > 3)
      about_us = about_us.slice(0, 3);
    const events = posts?.entities?.filter((entity) =>
      entity.categories?.find((c) => c.slug === "event")
    );

    let news_and_events: Post[] = posts?.entities?.filter((entity) =>
      entity.categories?.find((c) => c.slug === "news_and_events")
    ) || [];
    if (news_and_events?.length && news_and_events.length > 3)
      news_and_events = news_and_events.slice(0, 3);
    events?.sort(
      (a, b) => new Date(a.date!).getTime() - new Date(b.date!).getTime()
    );


    const next_event = events?.find(
      (entity) => new Date(entity.date!) > new Date()
    );
    let next_events = events?.filter(
      (entity) => new Date(entity.date!) > new Date()
    );
    if (next_events?.length && next_events.length > 4)
      next_events = next_events.slice(0, 4);
    let past_events = events?.filter(
      (entity) =>
        new Date(entity.date!) < new Date() && entity.featured_image_url
    );
    if (past_events?.length && past_events.length > 4)
      past_events = past_events.slice(0, 4);
    return {
      slides,
      welcome_message,
      next_event,
      about_us,
      next_events,
      past_events,
      news_and_events,
    };
  }, [posts]);
  useEffect(() => {
    if (next_event) {
      const date = new Date(next_event.date!);
      var headID = document.getElementsByTagName("body")[0];
      var newScript4 = document.createElement("script");
      newScript4.type = "text/javascript";
      newScript4.innerHTML = `	/*---------------  Countdown plugin  ------------------*/
	(function($){
	  // Constructor
	  	function Countdown( element, date ) {
		    this.$element = $(element);
		    this.date     = date;
		    this.init();
	  	}
	  	// Initialize interval countdown
	  	Countdown.prototype.init = function(){
	    var _this = this;	    
	    	setInterval(function(){
	      		var diff = _this.time_difference();	      
			    $('.days .count').text(diff.days);
			    $('.hours .count').text(diff.hours);
			    $('.minutes .count').text(diff.minutes);
			    $('.seconds .count').text(diff.seconds);
	    	}, 1000);	    
	  	};
	  	// Time difference between given date & current date
	  	// @return [object]
	  	Countdown.prototype.time_difference = function(){
	    	var days, hours, interval, minutes, seconds,	 
	        ms_per_minute = 1000 * 60,
	        ms_per_hour   = ms_per_minute * 60,
	        ms_per_day    = ms_per_hour * 24,	        
	        current_date  = new Date();	  
		    if ( current_date > this.date ) {
		      interval = current_date - this.date.getTime();
		    } else {
		      interval = this.date.getTime() - current_date;
		    }	   
		    days     = Math.floor(interval / ms_per_day);
		    interval = interval - (days * ms_per_day);

		    hours = Math.floor(interval / ms_per_hour);
		    interval = interval - (hours * ms_per_hour);

		    minutes = Math.floor(interval / ms_per_minute);
		    interval = interval - (minutes * ms_per_minute);
		    seconds = Math.floor(interval / 1000);
		    return { 
		      days: days, 
		      hours: hours, 
		      minutes: minutes, 
		      seconds: seconds
		    };

	  	};  
	  // jQuery plugin
	  	$.fn.countdown = function(date_a, date_b){
		    this.each(function(){
			    var $this = $(this);
			    if ( !$this.data('js-countdown') ) {
			        $this.data('js-countdown', new Countdown(this, date_a, date_b) );
			    }		      
		    });
	  	};
	}(jQuery));


	/*--------------- Initialize plugin ------------------*/
	$('.js-countdown').countdown( 
	  new Date(${date.getFullYear()},${date.getMonth()},${date.getDate()},0,0,0)
	// countdown from the below date
	// new Date(year,month,date,hours,minutes,seconds)
	);`;
      headID.appendChild(newScript4);
      return () => {
        headID.removeChild(newScript4);
      };
    }
  }, [next_event]);

  const { data: homilies } = useQuery({
    queryKey: ['homilies'],
    queryFn: () => getPosts(
      `&sort_field=id&sort_order=desc&filter={"status":true,"category_slug":["homilies"]}`
    ),
  });

  const [tabIndex, setTabIndex] = useState(0);

  const handleShowTab = (index: number) => {
    setTabIndex(index);
  };

  return (
    <>
      <div>
        <div>
          {modalOpen && (
            <div className="modal" id="overlay-event">
              <div className="modal-overlay" onClick={closeModal}></div>
              <div className="modal-content">
                <div>
                  <span
                    className="close"
                
                    id="close_auth"
                    onClick={closeModal}
                    style={{ display: "" }}
                  >
                    &times;
                  </span>
                  <br />
                  <br />
                  <br />
                  <br />

                  <div
                    className="modal-event clearfix"
                    style={{ overflowY: "auto", maxHeight: "30%" }}
                  >
                    <h1 className="logo" style={{ margin: 0 }}>
                      <Link to="/">
                        <img
                          src="/images/coat_of_arm.png"
                          alt="imag-logo"
                          style={{ height: "100px" }}
                        />
                      </Link>
                    </h1>
                    <Introduction />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="slider">
        {slides?.map((entity) => (
          <div key={entity.id} className="image-slider">
            <img
              className="img_slider"
              src={
                process.env.REACT_APP_BUCKET_URL! + entity?.featured_image_url
              }
              alt="image_text"
            />
          </div>
        ))}
      </div>
      <a href="#!" id="scrollUp">
        .
      </a>
      {/* End of header */}
      {next_event && (
        <section className="event">
          <div className="container">
            <div className="row">
              <figure className="left-fig clearfix">
                <div className="left-fig-img">
                  <i className="fa fa-calendar"></i>
                </div>
                <div className="left-fig-content">
                  <h5>upcoming Event</h5>
                  <h6>next</h6>
                </div>
              </figure>
              <ul className="right-fig js-countdown clearfix">
                <li>
                  <h5>{next_event.title}</h5>
                  <h6>{dayjs(next_event.date).format("DD MMM, YYYY")}</h6>
                </li>
                <li className="days">
                  <span className="count"></span>
                  <span>days</span>
                </li>
                <li className="hours">
                  <span className="count"></span>
                  <span>hrs</span>
                </li>
                <li className="minutes">
                  <span className="count"></span>
                  <span>min</span>
                </li>
                <li className="seconds">
                  <span className="count"></span>
                  <span>sec</span>
                </li>
                <li>
                  <a href="#events" className="btn red-btn">
                    all events
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      )}
      {/* End of event secton */}

      <div className="overlay"></div>
      <br />

      <br />
      <div className="text-center">
        <button className="btn red-btn" onClick={openModal}>
          REGISTER ON OUR DATABASE
        </button>
      </div>

      {welcome_message && (
        <section className="content-wrap">
          <div className="container">
            <div className="row">
              <div className="fig-content">
                <div className="content-items">
                  <img
                    src={
                      process.env.REACT_APP_BUCKET_URL! +
                      welcome_message.featured_image_url
                    }
                    alt="image_text"
                  />
                </div>
              </div>
              <div className="figcaption-content">
                <h3>{welcome_message.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: welcome_message.description!,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* End of content wrap section */}
      {about_us?.length && (
        <section className="about">
          <div className="container">
            <div className="line-heading">
              <h3>Discover Catholic Diocese Of Osogbo</h3>
            </div>
            <div className="row">
              <div>
                {about_us.map((entity) => (
                  <div key={entity.id} className="figure">
                    <div className="figcaption clearfix">
                      <div className="item">
                        <img
                          src={
                            process.env.REACT_APP_BUCKET_URL! +
                            entity?.featured_image_url
                          }
                          height={223}
                          alt="image_text"
                        />
                      </div>
                      <div className="item-container">
                        <h4 className="headline-lato">{entity.title}</h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: truncateString(entity.description!, 250),
                          }}
                        ></p>
                        <a
                          href="#!"
                          className="red-readmore"
                          onClick={() => {
                            handleReadMore(entity);
                          }}
                        >
                          read more
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
      {/* end of about section */}
      {gallery?.entities && (
        <figure className="gallery">
          <ul className="gallery-wrapper clearfix">
            {gallery.entities.map((entity) => (
              <li key={entity.id} className="items">
                <a
                  href={process.env.REACT_APP_BUCKET_URL + entity.url}
                  className="zoom "
                >
                  <img
                    className="gallery_img"
                    src={process.env.REACT_APP_BUCKET_URL + entity.url}
                    alt="image_text"
                    height={250}
                  />
                  <span className="h-effect">
                    <img
                      src="/images/gallery_hover_icon.png"
                      className="item-container"
                      alt="image_text"
                    />
                  </span>
                </a>
              </li>
            ))}
          </ul>
        </figure>
      )}

      
      
{news_and_events?.length && (
        <section className="about">
          <div className="container">
            <div className="line-heading">
              <h3>RECENT NEWS</h3>
            </div>
            <div className="row">
              <div>
                {news_and_events.map((entity) => (
                  <div key={entity.id} className="figure">
                    <div className="figcaption clearfix">
                      <div className="item">
                        <img
                          src={
                            process.env.REACT_APP_BUCKET_URL! +
                            entity?.featured_image_url
                          }
                          height={223}
                          alt="image_text"
                        />
                      </div>
                      <div className="item-container">
                        <h4 className="headline-lato">{entity.title}</h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: truncateString(entity.description!, 250),
                          }}
                        ></p>
                        <a
                          href="#!"
                          className="red-readmore"
                          onClick={() => {
                            handleReadMore(entity);
                          }}
                        >
                          read more
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}




      <section className="upcoming-event" id="events">
        <div className="container">
          <div className="line-heading">
            <h3>Upcoming Events</h3>
          </div>
          <div className="row">
            <div className="clearfix">
              {next_events?.map((entity) => (
                <div key={entity.id} className="figure">
                  <div className="item-fig">
                    <div className="item">
                      <i className="fa fa-calendar"></i>
                      <h5>{dayjs(entity.date).format("DD MMM, YYYY")}</h5>
                      <h6>{entity.location}</h6>
                    </div>
                  </div>
                  <div className="item-content">
                    <h4>{entity.title}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: truncateString(entity.description!, 50),
                      }}
                    ></p>
                    <a href="#!" className="btn-small trans-purple-btn">
                      Read more
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <div className="text-center">
        <button className="btn red-btn" onClick={openModal}>
          REGISTER ON OUR DATABASE
        </button>
        
      </div>

      <br />
      <br />

      <section className="">

      <div className="line-heading">
            <h3>Recent Sermons</h3>
          </div>
          <div className="container tabs-section">
          <div className="figure-wrapper">
          <div className="tabs --flex-start --bg-light">
          <div className="tabs-title">
            {homilies?.entities?.map((tab: any, index: number) => (
              <button
                key={tab.id}
                className={tabIndex === index ? "tab active-tab" : "tab"}
                onClick={() => handleShowTab(index)}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <div className="tabs-contents">
            {homilies?.entities?.map((tab: any, index: number) => (
              <article
                key={tab.id}
                className={tabIndex === index ? "contents active-contents" : "contents"}
              >
                <div className="article-title">{tab.title}</div>
                <div
                  dangerouslySetInnerHTML={{ __html: tab.description }} 
                />
              </article>
            ))}
       <i className="fa fa-chevron-down"></i>
          </div>
          
          <div className="scroll-indicator">
    <i className="fa fa-chevron-down"></i>
  </div>
        </div>
      </div>
      </div>
    </section>

      <section className="donation" style={{ backgroundColor: "#fff" }}>
        <div className="container">
          <div className="line-heading">
            <h3>donate to the Diocese</h3>
          </div>
          <a href="#!" className="prev-btn">
            <img src="/images/left-arrow.jpg" alt="image_text" />
          </a>
          <a href="#!" className="next-btn">
            <img src="/images/right_arrow.jpg" alt="image_text" />
          </a>
          <div className="slider-donation clearfix">
            <div className="figure-wrapper clearfix">
              <div className="item-holder clearfix">
                <div className="item-fig">
                  <img src="/images/content/donation1.jpg" alt="image_text" />
                  <a href="#!" className="btn btn-white">
                    Donate now
                  </a>
                </div>
                <div className="item-content">
                  <h4>
                    <br />
                    <span>Help our Mission of Evangelization</span>
                  </h4>
                  <p>
                    <b>Naira</b>
                    <br />
                    Account No: 6001525710
                    <br />
                    Account Name: Catholic Diocese of Osogbo
                    <br />
                    Bank Name: Heritage BanK
                    <br />
                    <br />
                    <b>US Dollars</b>
                    <br />
                    Account No: 0035608331
                    <br />
                    Account Name: Catholic Diocese of Osogbo
                    <br />
                    Bank Name: Guaranty Trust Bank (GTB)
                  </p>
                  {/* <span>Share this:</span>
										<div className='social-wrapper'>
											<a href='#!'>
												<i className='fa fa-facebook'></i>
											</a>
											<a href='#!'>
												<i className='fa fa-twitter'></i>
											</a>
											<a href='#!'>
												<i className='fa fa-youtube'></i>
											</a>
										</div> */}
                </div>{" "}
                {/* End of item content */}
              </div>{" "}
              {/* End of item holder */}
            </div>{" "}
            {/* End of figure wrap */}
            <div className="figure-wrapper clearfix">
              <div className="item-holder clearfix">
                <div className="item-fig">
                  <img src="/images/content/donation2.jpg" alt="image_text" />
                  <a href="#!" className="btn btn-white">
                    Donate now
                  </a>
                </div>
                <div className="item-content">
                  <h4>
                    Vocation Appeal <br />
                    <span>Support Our Priest</span>
                    <p>
                      join us in the mission of training the next generation of
                      catholic priests. Your donations will assist with
                      providing essential formation for our seminarians. Every
                      gift, no matter the size contributes to a future rich with
                      faith and spiritual growth.
                      <br />
                      Some give to the mission by going; others go by giving!
                    </p>
                  </h4>
                </div>{" "}
                {/* End of item content */}
              </div>{" "}
              {/* End of item holder */}
            </div>{" "}
            <div className="figure-wrapper clearfix">
              <div className="item-holder clearfix">
                <div className="item-fig">
                  <img src="/images/content/donation2.jpg" alt="image_text" />
                  <a href="#!" className="btn btn-white">
                    Donate now
                  </a>
                </div>
                <div className="item-content">
                  <h4>
                    Your Donations <br />
                    <span>Goes a Long Way in Helping</span>
                  </h4>
                  <p>
                    <b>Naira</b>
                    <br />
                    Account No: 6001525710
                    <br />
                    Account Name: Catholic Diocese of Osogbo
                    <br />
                    Bank Name: Heritage BanK
                    <br />
                    <br />
                    <b>US Dollars</b>
                    <br />
                    Account No: 0035608331
                    <br />
                    Account Name: Catholic Diocese of Osogbo
                    <br />
                    Bank Name: Guaranty Trust Bank(GTB)
                  </p>
                  {/*
									<span>Share this:</span>
									<div className='social-wrapper clearfix'>
										<a href='#!'>
											<i className='fa fa-facebook'></i>
										</a>
										<a href='#!'>
											<i className='fa fa-twitter'></i>
										</a>
										<a href='#!'>
											<i className='fa fa-youtube'></i>
										</a> 
									</div>*/}
                </div>{" "}
                {/* End of item content */}
              </div>{" "}
              {/* End of item holder */}
            </div>{" "}
            {/* End of figure wrap */}
          </div>{" "}
          {/* End of slider donation */}
        </div>{" "}
        {/* End of container */}
      </section>
      <section className="sermons">
        <div className="container">
          <div className="line-heading">
            <h3>Daily Readings</h3>
          </div>
          <div className="row text-center">
            <div className="figure-wrapper text-center" >
            <div style={{ height: '500px', width: '100%', maxWidth: '550px', overflow: 'hidden' }} className="fw-iframe figure clearfix">
  
            <iframe
      src="https://universalis.com/mass.htm"
      style={{ height: '100%', width: '100%', border: 'none' }}
      className="fw-iframe figure clearfix"
      title="Readings"
    ></iframe>
    </div>
            </div>

           
            <div style={{ height: '500px', width: '100%', maxWidth: '550px', overflow: 'hidden' }} className="fw-iframe figure clearfix">
            <iframe
      src="https://feed.mikle.com/widget/v2/167983/?preloader-text=Loading&"
      style={{ height: '100%', width: '100%', border: 'none' }}
      className="fw-iframe figure clearfix"
      title="Vatican News"
    ></iframe>
</div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Landing;
